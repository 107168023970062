.carousel
{
 width:100%;

}
@media screen and (max-width: 455px) {
   
    .carousel_btn{
    font-size: 20px !important;
    padding:10px !important;
    font-weight: bold;
    }
  }
  
  
@media screen and (max-width: 455px) {
    .carousel
    {
     width:100%
    }
    .carousel_btn{
    font-size: 20px !important;
    padding:2px !important;
    font-weight: bold;
    }
  }