.img_carousel 
{
    width:70%;
    height:auto;
}
.carousel_btn {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    text-align: inherit;
    text-decoration: none;
    cursor: pointer;
    font-size: 40px;
    padding:20px;
}


